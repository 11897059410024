@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply m-0 font-sans antialiased bg-white;
  }
  
  code {
    @apply font-mono;
  }
}
